@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

@layer base {
  ul,
  ol {
    list-style: revert;
    list-style-position: inside;
  }
}

/* src/index.css */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.custom-shape-green {
  background-color: #22c55e; /* Tailwind green-500 color */
  clip-path: polygon(50% 0%, 100% 25%, 100% 100%, 0% 100%, 0% 75%, 0% 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.custom-shape-red {
  background-color: #fc0032; /* Tailwind pink-500 color */
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 50% 100%, 0% 75%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

@layer components {
  .default-gradient {
    @apply bg-gradient-to-r from-indigo-500 to-purple-700;
  }

  .default-gradient-reverse {
    @apply bg-gradient-to-r to-purple-500 from-indigo-700;
  }
}
